import {
    loadSortingOptionsFailureAction,
    loadSortingOptionsSuccessAction,
    SortingOptionsActionTypes,
} from '@action/sorting-options/sorting-options.actions';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { FilterProps, FilterRequestParameters } from '@service/filter/filter';
import { ResponseResourceCollection } from '@service/http/response';
import { SortingOption } from '@service/sorting/sorting-option';
import { SortingService } from '@service/sorting/sorting.service';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

@Injectable()
export class SortingOptionsEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly sortingService: SortingService,
        private readonly route: ActivatedRoute,
    ) {}

    /**
     * Load sorting options
     *
     * @returns Observable
     *
     * @author Sander van Ooijen <sander@bsbip.com>
     * @author Rody Korthout <rody@bsbip.com>
     *
     * @version 1.0.1
     */
    public loadSortingOptions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SortingOptionsActionTypes.LOAD_SORTING_OPTIONS),
            mergeMap((data: FilterProps<FilterRequestParameters>) =>
                this.sortingService
                    .index({
                        ...data.payload,
                        object: data.payload.objectType,
                    })
                    .pipe(
                        map(
                            (
                                response: ResponseResourceCollection<SortingOption>,
                            ) =>
                                loadSortingOptionsSuccessAction({
                                    objectType: data.payload.objectType,
                                    data: response.data,
                                    queryParams: this.route.snapshot
                                        .queryParams,
                                    orderByLimit: data.payload.orderByLimit,
                                }),
                        ),
                        catchError((error: HttpErrorResponse) =>
                            of(
                                loadSortingOptionsFailureAction({
                                    objectType: data.payload.objectType,
                                    error,
                                }),
                            ),
                        ),
                    ),
            ),
        ),
    );
}
