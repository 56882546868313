import { Injectable } from '@angular/core';

import { AppState } from '@app/app.state';
import { AuthenticationState } from '@reducer/authenticated-user/authentication.reducer';
import { environment } from '@environment/environment';
import { Store } from '@ngrx/store';
import { of, Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { ResponseType } from './response-type.alias';

@Injectable({
    providedIn: 'root',
})
export class TermsGuard  {
    private authenticationState: AuthenticationState;
    private readonly authentication$: Subscription;

    constructor(private readonly store: Store<AppState>) {
        this.authentication$ = this.store
            .select((state: AppState) => state.authentication)
            .pipe(distinctUntilChanged())
            .subscribe((state: AuthenticationState) => {
                this.authenticationState = state;
            });
    }
    /**
     * Handle can activate.
     *
     * @author Ramon Bakker <ramon@bsbip.com>
     * @version 1.0.0
     */
    public canActivate(): ResponseType {
        return this.handle();
    }

    /**
     * Handle can activate child.
     *
     * @author Ramon Bakker <ramon@bsbip.com>
     * @version 1.0.0
     */
    public canActivateChild(): ResponseType {
        return this.handle();
    }

    /**
     * Handle guard.
     *
     * @returns an observable or promise
     *
     * @author Ramon Bakker <ramon@bsbip.com>
     * @version 1.0.0
     */
    private handle(): ResponseType {
        if (!this.authenticationState.authenticated) {
            this.authentication$.unsubscribe();
            return of(true);
        } else {
            if (this.authenticationState.user.termsAccepted) {
                this.authentication$.unsubscribe();
            }
            return of(
                this.authenticationState.user.termsAccepted ||
                    !environment.avgModalActive,
            );
        }
    }
}
