import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, map, catchError } from 'rxjs/operators';
import {
    ResponseResource,
    ResponseResourceCollection,
} from '@service/http/response';
import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';
import { StaticBlockService } from '@service/static-block/static-block.service';
import {
    loadStaticBlocksFailureAction,
    loadStaticBlocksSuccessAction,
    showStaticBlockFailureAction,
    showStaticBlockSuccessAction,
    StaticBlockActionTypes,
} from '@action/static-block/static-block.actions';
import {
    StaticBlocksProps,
    StaticBlock,
    StaticBlockProps,
} from '@service/static-block/static-block';

@Injectable()
export class StaticBlockEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly staticBlockService: StaticBlockService,
    ) {}

    /**
     * Load static block effect
     *
     * @returns {Observable}
     *
     * @author Niek van der Velde <niek@bsbip.com>
     * @version 1.1.0
     */
    public showStaticBlock$ = createEffect(() =>
        this.actions$.pipe(
            ofType(StaticBlockActionTypes.SHOW_STATIC_BLOCK),
            mergeMap((data: StaticBlockProps) =>
                this.staticBlockService.show(data.payload).pipe(
                    map((response: ResponseResource<StaticBlock>) =>
                        showStaticBlockSuccessAction(response),
                    ),
                    catchError((error: HttpErrorResponse) =>
                        of(
                            showStaticBlockFailureAction({
                                error,
                            }),
                        ),
                    ),
                ),
            ),
        ),
    );

    /**
     * Load static blocks effect.
     *
     * @returns {Observable}
     *
     * @author Niek van der Velde <niek@bsbip.com>
     * @version 1.1.0
     */
    public loadStaticBlocks$ = createEffect(() =>
        this.actions$.pipe(
            ofType(StaticBlockActionTypes.LOAD_STATIC_BLOCKS),
            mergeMap((data: StaticBlocksProps) =>
                this.staticBlockService.index(data.payload).pipe(
                    map((response: ResponseResourceCollection<StaticBlock>) =>
                        loadStaticBlocksSuccessAction(response),
                    ),
                    catchError((error: HttpErrorResponse) =>
                        of(
                            loadStaticBlocksFailureAction({
                                error,
                            }),
                        ),
                    ),
                ),
            ),
        ),
    );
}
