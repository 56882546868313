import {
    GeoDataActionsEnum,
    loadGeoDataFailureAction,
    loadGeoDataSuccessAction,
} from '@action/geo-data/geo-data.actions';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { GeoData } from '@service/geo-data/geo-data';
import { GeoDataService } from '@service/geo-data/geo-data.service';
import { ResponseResource } from '@service/http/response';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

@Injectable()
export class GeoDataEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly geoDataService: GeoDataService,
    ) {}

    /**
     * Load geo data effect
     *
     * @author Sander van Ooijen <svanooijen@bettercollective.com>
     * @version 1.0.0
     */
    public loadGeoData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GeoDataActionsEnum.LOAD_GEO_DATA),
            mergeMap(() =>
                this.geoDataService.show().pipe(
                    map((response: ResponseResource<GeoData>) =>
                        loadGeoDataSuccessAction(response),
                    ),
                    catchError((error: HttpErrorResponse) =>
                        of(loadGeoDataFailureAction({ error })),
                    ),
                ),
            ),
        ),
    );
}
