import { loadGeoDataAction } from '@action/geo-data/geo-data.actions';
import { AppState } from '@app/app.state';
import { Store } from '@ngrx/store';
import { selectGeoData } from '@selector/geo-data/geo-data.selectors';
import { take } from 'rxjs/operators';

export const geoDataFactory = (store: Store<AppState>) => () =>
    new Promise<void>((resolve: (value?: void | PromiseLike<void>) => void) => {
        store.dispatch(loadGeoDataAction());

        store
            .select(selectGeoData)
            .pipe(take(1))
            .subscribe(() => resolve());
    });
