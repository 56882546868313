import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { GeoData } from '@service/geo-data/geo-data';
import { ResponseResource } from '@service/http/response';

export enum GeoDataActionsEnum {
    LOAD_GEO_DATA = '[GEO DATA] Load geo data',
    LOAD_GEO_DATA_SUCCESS = '[GEO DATA] Load geo data success',
    LOAD_GEO_DATA_FAILURE = '[GEO DATA] Load geo data failure',
}

export const loadGeoDataAction = createAction(GeoDataActionsEnum.LOAD_GEO_DATA);

export const loadGeoDataSuccessAction = createAction(
    GeoDataActionsEnum.LOAD_GEO_DATA_SUCCESS,
    props<ResponseResource<GeoData>>(),
);

export const loadGeoDataFailureAction = createAction(
    GeoDataActionsEnum.LOAD_GEO_DATA_FAILURE,
    props<{ error: HttpErrorResponse }>(),
);
