import {
    loadMetadataFailureAction,
    loadMetadataSuccessAction,
    MetadataActionTypes,
    storeMetadataFailureAction,
    storeMetadataSuccessAction,
    updateMetadataFailureAction,
    updateMetadataSuccessAction,
} from '@action/metadata/metadata.actions';
import { DOCUMENT } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Emoji } from '@core/enums/emoji';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { DomService } from '@service/dom.service';
import { ResponseResource } from '@service/http/response';
import {
    Metadata,
    MetadataProps,
    MetadataRequestParameters,
    MetadataUrl,
    MetadataUrlForm,
} from '@service/metadata/metadata';
import { MetadataService } from '@service/metadata/metadata.service';
import { HeaderService } from '@shared/components/header/header.service';
import { decodeHtmlEntities } from '@shared/filters/decode-html-entities';
import format from 'date-fns/format';
import nl from 'date-fns/locale/nl';
import { catchError, map, mergeMap, of } from 'rxjs';

@Injectable()
export class MetadataEffects {
    constructor(
        @Inject(DOCUMENT) private document: Document,
        private readonly actions$: Actions,
        private readonly metadataService: MetadataService,
        private readonly headerService: HeaderService,
        private readonly router: Router,
        private readonly domService: DomService,
    ) {}

    /**
     * Load metadata
     *
     * @returns Observable
     *
     * @author Sander van Ooijen <sander@bsbip.com>
     * @version 1.0.0
     */
    public loadMetadata$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MetadataActionTypes.LOAD_METADATA),
            mergeMap((data: MetadataProps<MetadataRequestParameters>) => {
                const options = data.payload.options;

                const codeNameAppendix = options?.notRelevant
                    ? '_not_relevant'
                    : '';

                return this.metadataService
                    .show(
                        this.router.url.split('?')[0].split('#')[0],
                        `${data.payload.codeName}${codeNameAppendix}`,
                    )
                    .pipe(
                        map((response: ResponseResource<Metadata>) => {
                            let metadata = { ...response.data };

                            metadata.url = this.router.url.includes('?')
                                ? this.router.url.slice(
                                      0,
                                      this.router.url.indexOf('?'),
                                  )
                                : this.router.url;

                            const replacements =
                                data.payload.replacements ?? {};

                            metadata = {
                                ...this.replaceValues(metadata, {
                                    ...replacements,
                                    day:
                                        replacements['day'] ??
                                        new Date().getDate(),
                                    month:
                                        replacements['month'] ??
                                        format(new Date(), 'LLLL', {
                                            locale: nl,
                                        }),
                                    year:
                                        replacements['year'] ??
                                        new Date().getFullYear(),
                                }),
                            };

                            this.setMetadata(metadata);

                            return loadMetadataSuccessAction({
                                data: metadata,
                            });
                        }),
                        catchError((error: HttpErrorResponse) => {
                            this.setMetadata();

                            return of(
                                loadMetadataFailureAction({
                                    error,
                                }),
                            );
                        }),
                    );
            }),
        ),
    );

    /**
     * Update metadata
     *
     * @returns Observable
     *
     * @author Sander van Ooijen <sander@bsbip.com>
     * @version 1.0.0
     */
    public updateMetadataUrl$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MetadataActionTypes.UPDATE_METADATA_URL),
            mergeMap((data: MetadataProps<MetadataUrlForm>) =>
                this.metadataService.update(data.payload.id, data.payload).pipe(
                    map((response: ResponseResource<MetadataUrl>) => {
                        const metadata: Metadata = {
                            ...response.data.metadata,
                            url: response.data.url,
                            metadataUrl: response.data,
                        };

                        metadata.metadataUrl = {
                            ...metadata.metadataUrl,
                            metadata: null,
                        };

                        this.setMetadata(metadata);

                        return updateMetadataSuccessAction({
                            data: metadata,
                        });
                    }),
                    catchError((error: HttpErrorResponse) =>
                        of(
                            updateMetadataFailureAction({
                                error: error.error,
                            }),
                        ),
                    ),
                ),
            ),
        ),
    );

    /**
     * Store metadata
     *
     * @returns Observable
     *
     * @author Sander van Ooijen <sander@bsbip.com>
     * @version 1.0.0
     */
    public storeMetadataUrl$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MetadataActionTypes.STORE_METADATA_URL),
            mergeMap((data: MetadataProps<MetadataUrlForm>) =>
                this.metadataService.store(data.payload).pipe(
                    map((response: ResponseResource<MetadataUrl>) => {
                        const metadata: Metadata = {
                            ...response.data.metadata,
                            url: response.data.url,
                            metadataUrl: response.data,
                        };

                        metadata.metadataUrl = {
                            ...metadata.metadataUrl,
                            metadata: null,
                        };

                        this.setMetadata(metadata);

                        return storeMetadataSuccessAction({
                            data: metadata,
                        });
                    }),
                    catchError((error: HttpErrorResponse) =>
                        of(
                            storeMetadataFailureAction({
                                error: error.error,
                            }),
                        ),
                    ),
                ),
            ),
        ),
    );

    /**
     * Set metadata (with replacements) with Angulars Meta and Title services.
     *
     * @param metadata
     * @param replacements
     *
     * @author Roy Freij <roy@bsbip.com>
     * @author Sander van Ooijen <sander@bsbip.com>
     * @version 2.0.1
     */
    private setMetadata(metadata: Metadata = undefined): void {
        if (!metadata) {
            return;
        }

        this.metadataService.title = metadata.metaTitle;
        this.metadataService.description = metadata.metaDescription;

        if (metadata.canonicalTag && metadata.canonicalTag !== '') {
            this.metadataService.canonical = metadata.canonicalTag;
        }

        // Exceptions: https://betterc.atlassian.net/browse/VW-13605
        if (
            metadata.metadataCodeName?.codeName !== 'Tips' &&
            metadata.metadataCodeName?.codeName !== 'subleague' &&
            metadata.metadataCodeName?.codeName !== 'events'
        ) {
            if (!metadata.h1) {
                this.headerService.setTitle(metadata.metaTitle);
                this.headerService.setAltTag(metadata.metaTitle);
            } else {
                this.headerService.setTitle(metadata.h1);
                this.headerService.setAltTag(metadata.h1);
            }
        }

        this.metadataService.setRobots(
            metadata.metaRobotsIndex,
            metadata.metaRobotsFollow,
        );

        this.metadataService.setOpenGraphMeta(
            metadata.metaTitle,
            metadata.metaDescription,
        );

        // https://betterc.atlassian.net/browse/VW-14488
        this.domService.nativeWindow.dataLayer.push({
            event: 'Pageview',
            data: {
                pagePath: metadata.url,
                pageTitle: metadata.metaTitle,
            },
        });
    }

    /**
     * Replace placeholders in the metadata
     *
     * @param data
     * @param replacements
     *
     * @returns Metadata
     *
     * @author Roy Freij <roy@bsbip.com>
     * @author Sander van Ooijen <sander@bsbip.com>
     * @version 1.0.2
     */
    private replaceValues(data: Metadata, replacements: object = {}): Metadata {
        Object.keys(replacements).forEach((replacementKey: string) => {
            if (replacementKey === 'emoji') {
                Object.keys(Emoji).forEach((key: string) => {
                    if (
                        isNaN(Number(key)) &&
                        Emoji[key] === replacements[replacementKey]
                    ) {
                        replacements[replacementKey] = key.replace(/\s/g, '');
                        replacements[replacementKey] = decodeHtmlEntities(
                            replacements[replacementKey],
                            this.document,
                        );
                    }
                });
            }

            Object.keys(data).forEach((codeName: string) => {
                if (typeof data[codeName] === 'string') {
                    data[codeName] = data[codeName].replace(
                        new RegExp(`#${replacementKey}#`, 'g'),
                        replacements[replacementKey],
                    );
                }
            });
        });

        return data;
    }
}
