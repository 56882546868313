import { Injectable } from '@angular/core';
import { HttpService } from '@service/http/http.service';
import { ResponseResourceCollection } from '@service/http/response';
import { Observable } from 'rxjs';
import { SortingOption } from './sorting-option';

@Injectable({
    providedIn: 'root',
})
export class SortingService {
    constructor(protected readonly httpService: HttpService) {}

    /**
     * Get sorting options
     *
     * @param object
     *
     * @returns Observable
     *
     * @author Roy Freij
     * @author Sander van Ooijen <sander@bsbip.com>
     * @version 1.0.2
     */
    public index(
        params: object,
    ): Observable<ResponseResourceCollection<SortingOption>> {
        return this.httpService.get('/v2/sorting-options', {
            params,
        });
    }
}
