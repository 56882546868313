import {
    FiltersActionTypes,
    loadFiltersFailureAction,
    loadFiltersSuccessAction,
} from '@action/filters/filters.actions';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AnalyticsService } from '@service/analytics/analytics.service';
import {
    FilterProps,
    FilterRequestParameters,
    FilterResource,
    FilterUpdateParameters,
} from '@service/filter/filter';
import { FilterService } from '@service/filter/filter.service';
import { ResponseResourceCollection } from '@service/http/response';
import { toNormal } from '@shared/filters/str/to-normal.function';
import { of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';

@Injectable()
export class FiltersEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly filterService: FilterService,
        private readonly route: ActivatedRoute,
        private readonly analyticsService: AnalyticsService,
    ) {}

    /**
     * Load filters effect
     *
     * @returns Observable
     *
     * @author Sander van Ooijen <sander@bsbip.com>
     * @version 1.0.0
     */
    public loadFilters$ = createEffect(() =>
        this.actions$.pipe(
            ofType(FiltersActionTypes.LOAD_FILTERS),
            mergeMap((data: FilterProps<FilterRequestParameters>) =>
                this.filterService.index(data.payload).pipe(
                    map(
                        (
                            response: ResponseResourceCollection<FilterResource>,
                        ) =>
                            loadFiltersSuccessAction({
                                objectType: data.payload.objectType,
                                data: response.data,
                                queryParams: this.route.snapshot.queryParams,
                            }),
                    ),
                    catchError((error: HttpErrorResponse) =>
                        of(
                            loadFiltersFailureAction({
                                objectType: data.payload.objectType,
                                error,
                            }),
                        ),
                    ),
                ),
            ),
        ),
    );

    /**
     * Update filter effect
     *
     * @returns Observable
     *
     * @author Sander van Ooijen <sander@bsbip.com>
     * @version 1.0.0
     */
    public updateFilter$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(FiltersActionTypes.UPDATE_FILTER),
                tap((data: FilterProps<FilterUpdateParameters>) =>
                    this.analyticsService.interaction(
                        data.payload.name,
                        'filter',
                        `${toNormal(data.payload.objectType)}: ${
                            data.payload.value.label
                        }, ${
                            !data.payload.value.isActive ? 'Active' : 'Inactive'
                        }`,
                    ),
                ),
            ),
        { dispatch: false },
    );
}
