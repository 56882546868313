import { HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';

/**
 * Intercepts requests that have an orderBy with _reverse params
 * And adds orderDirection desc param if found
 *
 * @param request
 * @param next
 *
 * @returns A HttpEvent
 *
 * @author Sander van Ooijen <svanooijen@bettercollective.com>
 * @version 2.0.0
 */
export const orderDirectionInterceptor: HttpInterceptorFn = (
    request: HttpRequest<unknown>,
    next: HttpHandlerFn,
) => {
    if (request.params.get('orderBy')) {
        let orderDirection = 'asc';

        if (request.params.get('orderBy').includes('_reverse')) {
            orderDirection = 'desc';
        }

        if (request.params.has('orderDirection')) {
            orderDirection = request.params.get('orderDirection');
        }

        request = request.clone({
            params: request.params
                .set('orderDirection', orderDirection)
                .set(
                    'orderBy',
                    request.params.get('orderBy').replace('_reverse', ''),
                ),
        });
    }

    return next(request);
};
