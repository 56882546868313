import { Injectable } from '@angular/core';
import { HttpService } from '@service/http/http.service';
import {
    ResponseResource,
    ResponseResourceCollection,
} from '@service/http/response';
import { Observable } from 'rxjs';
import {
    UserTopic,
    UserTopicIndexParameters,
    UserTopicStoreParameters,
} from './user-topic';

const baseUrl = '/v2/';

@Injectable({
    providedIn: 'root',
})
export class UserTopicService {
    constructor(private readonly httpService: HttpService) {}

    /**
     * Index user topics.
     *
     * @param userTopicStoreParameters
     *
     * @returns user topics
     *
     * @author Sander van Ooijen <svanooijen@bettercollective.com>
     * @version 1.0.0
     */
    public index(
        userTopicStoreParameters: UserTopicIndexParameters,
    ): Observable<ResponseResourceCollection<UserTopic>> {
        return this.httpService.get(
            `${baseUrl}user-forum-topics`,
            userTopicStoreParameters,
        );
    }

    /**
     * Store an user topic.
     *
     * @param userTopicStoreParameters
     *
     * @returns user topic
     *
     * @author Ramon Bakker <ramon@bsbip.com>
     * @author Niek van der Velde <niek@bsbip.com>
     * @version 1.1.0
     */
    public store(
        userTopicStoreParameters: UserTopicStoreParameters,
    ): Observable<ResponseResource<UserTopic>> {
        return this.httpService.post(
            `${baseUrl}user-forum-topics`,
            userTopicStoreParameters,
        );
    }

    /**
     * Delete an user topic.
     *
     * @param id
     *
     * @returns observable
     *
     * @author Ramon Bakker <ramon@bsbip.com>
     * @author Niek van der Velde <niek@bsbip.com>
     * @version 1.1.0
     */
    public delete(id: number): Observable<Array<void>> {
        return this.httpService.delete(`${baseUrl}user-forum-topics/${id}`);
    }
}
