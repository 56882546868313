import { initialListState, ListState } from '@action/list-actions.actions';
import {
    createUserVoteAction,
    createUserVoteFailureAction,
    createUserVoteSuccessAction,
    deleteUserVoteAction,
    deleteUserVoteFailureAction,
    deleteUserVoteSuccessAction,
} from '@action/users/user-votes.actionts';
import { HttpErrorResponse } from '@angular/common/http';
import { Action, createReducer, on } from '@ngrx/store';
import { ResponseResource } from '@service/http/response';
import { Vote, VoteProps, VoteRequest } from '@service/vote/vote';

export const reducer = (state: ListState<Vote>, action: Action) =>
    createReducer(
        initialListState,

        on(
            createUserVoteAction,
            (listState: ListState<Vote>, request: VoteProps<VoteRequest>) => ({
                ...listState,
                loading: true,
                data: [...state.data, { ...request.payload, isDeleted: false }],
            }),
        ),
        on(
            createUserVoteSuccessAction,
            (listState: ListState<Vote>, payload: ResponseResource<Vote>) => ({
                ...listState,
                loading: false,
                data: [
                    ...state.data.map((vote: Vote) => {
                        if (
                            vote.votableId === payload.data.votableId &&
                            vote.votableType === payload.data.votableType &&
                            vote.userId === payload.data.userId
                        ) {
                            vote = {
                                ...vote,
                                id: payload.data.id,
                            };
                        }

                        return vote;
                    }),
                ],
            }),
        ),
        on(
            createUserVoteFailureAction,
            (
                listState: ListState<Vote>,
                payload: { request: VoteRequest; error: HttpErrorResponse },
            ) => ({
                ...listState,
                loading: false,
                data: [
                    ...state.data.filter(
                        (vote: Vote) =>
                            vote.votableId !== payload.request.votableId &&
                            vote.votableType !== payload.request.votableType &&
                            vote.userId !== payload.request.userId,
                    ),
                ],
            }),
        ),

        on(
            deleteUserVoteAction,
            (listState: ListState<Vote>, payload: { id: number }) => ({
                ...listState,
                loading: false,
                data: [
                    ...state.data.map((vote: Vote) => {
                        if (vote.id === payload.id) {
                            vote = {
                                ...vote,
                                isDeleted: true,
                            };
                        }

                        return vote;
                    }),
                ],
            }),
        ),
        on(
            deleteUserVoteSuccessAction,
            (listState: ListState<Vote>, payload: { id: number }) => ({
                ...listState,
                loading: false,
                data: [
                    ...state.data.filter(
                        (vote: Vote) => vote.id !== payload.id,
                    ),
                ],
            }),
        ),
        on(
            deleteUserVoteFailureAction,
            (listState: ListState<Vote>, payload: { id: number }) => ({
                ...listState,
                loading: false,
                data: [
                    ...state.data.map((vote: Vote) => {
                        if (vote.id === payload.id) {
                            vote = {
                                ...vote,
                                isDeleted: false,
                            };
                        }

                        return vote;
                    }),
                ],
            }),
        ),
    )(state, action);
