import { HttpEvent, HttpHandlerFn, HttpInterceptorFn, HttpRequest, HttpResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { MicrocopyService } from '@service/microcopy/microcopy.service';
import { map } from 'rxjs/operators';

/**
 * Intercepts requests that include the mircocopy, and sets that microcopy in the service.
 *
 * @param request
 * @param next
 *
 * @returns A HttpEvent
 *
 * @author Sander van Ooijen <svanooijen@bettercollective.com>
 * @version 1.0.0
 */
export const microcopyInterceptor: HttpInterceptorFn = (
    request: HttpRequest<unknown>,
    next: HttpHandlerFn,
) => {
    const microcopyService = inject(MicrocopyService);

    return next(request).pipe(
        map((event: HttpEvent<unknown>) => {
            if (
                event instanceof HttpResponse &&
                (event.body as any)?.meta?.microcopy
            ) {
                microcopyService.setMicrocopy(
                    (event.body as any).meta.microcopy,
                );
            }

            return event;
        }),
    );
};
