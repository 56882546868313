import {
    loadTipOfTheDayAction,
    loadTipOfTheDayFailureAction,
    loadTipOfTheDaySuccessAction,
    updateTipOfTheDayAction,
    updateTipOfTheDayFailureAction,
    updateTipOfTheDaySuccessAction,
} from '@action/betting-tips/tip-of-the-day.actions';
import {
    defaultShowItemAction,
    defaultShowItemFailureAction,
    defaultShowItemSuccessAction,
    FormState,
    initialFormState,
} from '@action/form-actions.actions';
import { HttpErrorResponse } from '@angular/common/http';
import { Action, createReducer, on } from '@ngrx/store';
import { ResponseResource } from '@service/http/response';
import { Tip } from '@service/tip/tip';

const TipOfTheDayReducer = createReducer(
    initialFormState,

    on(loadTipOfTheDayAction, (state: FormState<Tip>) =>
        defaultShowItemAction(state),
    ),
    on(
        loadTipOfTheDaySuccessAction,
        (state: FormState<Tip>, payload: ResponseResource<Tip>) =>
            defaultShowItemSuccessAction(state, payload),
    ),
    on(
        loadTipOfTheDayFailureAction,
        (
            state: FormState<Tip>,
            payload: {
                error: HttpErrorResponse;
            },
        ) => defaultShowItemFailureAction(state, payload.error),
    ),

    on(updateTipOfTheDayAction, (state: FormState<Tip>) =>
        defaultShowItemAction(state),
    ),
    on(
        updateTipOfTheDaySuccessAction,
        (state: FormState<Tip>, payload: ResponseResource<Tip>) =>
            defaultShowItemSuccessAction(state, payload),
    ),
    on(
        updateTipOfTheDayFailureAction,
        (
            state: FormState<Tip>,
            payload: {
                error: HttpErrorResponse;
            },
        ) => defaultShowItemFailureAction(state, payload.error),
    ),
);

export function reducer(
    state: FormState<Tip> | undefined,
    action: Action,
): FormState<Tip> {
    return TipOfTheDayReducer(state, action);
}
