import {
    defaultResetItemAction,
    defaultShowItemAction,
    defaultShowItemFailureAction,
    defaultShowItemSuccessAction,
    defaultStoreItemAction,
    defaultStoreItemFailureAction,
    defaultStoreItemSuccessAction,
    defaultUpdateItemAction,
    defaultUpdateItemFailureAction,
    defaultUpdateItemSuccessAction,
    FormState,
    initialFormState,
} from '@action/form-actions.actions';
import {
    loadMetadataAction,
    loadMetadataFailureAction,
    loadMetadataSuccessAction,
    resetMetadataAction,
    storeMetadataAction,
    storeMetadataFailureAction,
    storeMetadataSuccessAction,
    updateMetadataAction,
    updateMetadataFailureAction,
    updateMetadataSuccessAction,
} from '@action/metadata/metadata.actions';
import { HttpErrorResponse } from '@angular/common/http';
import { Action, createReducer, on } from '@ngrx/store';
import { ResponseResource } from '@service/http/response';
import {
    Metadata,
    MetadataProps,
    MetadataRequestParameters,
    MetadataUrl,
    MetadataUrlForm,
} from '@service/metadata/metadata';

export const metadataFeatureKey = 'metadata';

const metadataReducer = createReducer(
    initialFormState,
    on(
        loadMetadataAction,
        (
            state: FormState<Metadata>,
            props: MetadataProps<MetadataRequestParameters>,
        ) => defaultShowItemAction<Metadata>(state),
    ),
    on(
        loadMetadataSuccessAction,
        (state: FormState<Metadata>, payload: ResponseResource<Metadata>) =>
            defaultShowItemSuccessAction<Metadata>(state, payload),
    ),
    on(
        loadMetadataFailureAction,
        (state: FormState<Metadata>, payload: { error: HttpErrorResponse }) =>
            defaultShowItemFailureAction<Metadata>(state, payload),
    ),

    on(
        updateMetadataAction,
        (state: FormState<Metadata>, props: MetadataProps<MetadataUrlForm>) =>
            defaultUpdateItemAction<Metadata>(state),
    ),
    on(
        updateMetadataSuccessAction,
        (state: FormState<Metadata>, payload: ResponseResource<Metadata>) =>
            defaultUpdateItemSuccessAction<Metadata>(state, payload),
    ),
    on(
        updateMetadataFailureAction,
        (state: FormState<Metadata>, payload: { error: HttpErrorResponse }) =>
            defaultUpdateItemFailureAction<Metadata>(state, payload.error),
    ),

    on(
        storeMetadataAction,
        (state: FormState<Metadata>, props: MetadataProps<MetadataUrlForm>) =>
            defaultStoreItemAction<Metadata>(state),
    ),
    on(
        storeMetadataSuccessAction,
        (state: FormState<Metadata>, payload: ResponseResource<Metadata>) =>
            defaultStoreItemSuccessAction<Metadata>(state, payload),
    ),
    on(
        storeMetadataFailureAction,
        (state: FormState<Metadata>, payload: { error: HttpErrorResponse }) =>
            defaultStoreItemFailureAction<Metadata>(state, payload.error),
    ),

    on(resetMetadataAction, (state: FormState<Metadata>) =>
        defaultResetItemAction<Metadata>({ ...state, saveIsSuccessful: false }),
    ),
);

export function reducer(
    state: FormState<MetadataUrl> | undefined,
    action: Action,
): FormState<MetadataUrl> {
    return metadataReducer(state, action);
}
