import { TipOfTheDayEffects } from '@effect/betting-tips/tip-of-the-day.effects';
import { FiltersEffects } from '@effect/filters/filters.effects';
import { GeoDataEffects } from '@effect/geo-data/geo-data.effects';
import { MetadataEffects } from '@effect/metadata/metadata.effects';
import { NavigationBannerEffects } from '@effect/navigation/banner.effects';
import { NotificationsEffects } from '@effect/notifications/notifications.effects';
import { SortingOptionsEffects } from '@effect/sorting-options/sorting-options.effects';
import { StaticBlockEffects } from '@effect/static-block/static-block.effects';
import { UserForumTopicsEffects } from '@effect/users/user-forum-topics.effects';
import { UserTipCompetitionsEffects } from '@effect/users/user-tip-competitions.effects';
import { UserVotesEffects } from '@effect/users/user-votes.effects';
import { routerReducer } from '@ngrx/router-store';
import { ActionReducerMap } from '@ngrx/store';
import * as fromTipOfTheDay from '@reducer/betting-tips/tip-of-the-day.reducer';
import * as fromFilters from '@reducer/filters/filters.reducer';
import * as fromGeoData from '@reducer/geo-data/geo-data.reducer';
import * as fromMetadata from '@reducer/metadata/metadata.reducer';
import * as fromNavigationBanner from '@reducer/navigation/banner.reducer';
import * as fromNavigationItems from '@reducer/navigation/navigation-items.reducer';
import * as fromNotifications from '@reducer/notifications/notifications.reducer';
import * as fromSortingOptions from '@reducer/sorting-options/sorting-options.reducer';
import { staticBlockReducer } from '@reducer/static-block/static-block.reducer';
import * as fromUserForumTopics from '@reducer/users/user-forum-topics.reducer';
import * as fromUserTipCompetitions from '@reducer/users/user-tip-competitions.reducer';
import * as fromUserVotes from '@reducer/users/user-votes.reducer';
import { AuthenticationEffects } from './authenticated-user/authentication.effects';
import { authenticationReducer } from './authenticated-user/authentication.reducer';

export const reducers: ActionReducerMap<{}> = {
    authentication: authenticationReducer,

    geoData: fromGeoData.reducer,

    metadata: fromMetadata.reducer,

    navigationItems: fromNavigationItems.reducer,
    navigationBannerState: fromNavigationBanner.reducer,

    filters: fromFilters.reducer,
    sortingOptions: fromSortingOptions.reducer,

    notifications: fromNotifications.reducer,

    staticBlock: staticBlockReducer,

    userForumTopics: fromUserForumTopics.reducer,
    userTipCompetitions: fromUserTipCompetitions.reducer,

    userVotes: fromUserVotes.reducer,
    tipOfTheDay: fromTipOfTheDay.reducer,

    router: routerReducer,
};

export const effects = [
    AuthenticationEffects,
    MetadataEffects,
    FiltersEffects,
    SortingOptionsEffects,
    StaticBlockEffects,
    NavigationBannerEffects,
    UserForumTopicsEffects,
    UserTipCompetitionsEffects,
    NotificationsEffects,
    TipOfTheDayEffects,
    UserVotesEffects,
    GeoDataEffects,
];
