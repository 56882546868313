import { AppState } from '@app/app.state';
import { Store } from '@ngrx/store';
import { verifyWithTokenAction } from '@reducer/authenticated-user/authentication.actions';
import { selectAuthenticated } from '@reducer/authenticated-user/authentication.selectors';
import { AuthService } from '@service/auth/auth.service';
import { DomService } from '@service/dom.service';
import { filter, take } from 'rxjs/operators';

export const authenticationFactory =
    (
        store: Store<AppState>,
        authService: AuthService,
        domService: DomService,
    ) =>
    () =>
        new Promise<void>(
            (resolve: (value?: void | PromiseLike<void>) => void) => {
                if (!authService.getToken() || !domService.isBrowser) {
                    return resolve();
                }

                store.dispatch(verifyWithTokenAction());

                store
                    .select(selectAuthenticated)
                    .pipe(
                        filter(
                            (authenticated: boolean) => authenticated !== null,
                        ),
                        take(1),
                    )
                    .subscribe(() => resolve());
            },
        );
