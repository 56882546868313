import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from '@app/app.component';
import { browserConfig } from '@app/app.config.browser';
import { ReleaseStage } from '@app/release-stage.enum';
import { environment } from './environments/environment';

const head = document.getElementsByTagName('head')[0];
const body = document.getElementsByTagName('body')[0];

// GTM
const gtmScript = document.createElement('script');
gtmScript.setAttribute('async', '');
gtmScript.innerHTML = `
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${environment.tagManager.trackingId}');
`;

// GTM Initialize Script
const gtmInitScript = document.createElement('script');
gtmInitScript.setAttribute('defer', '');
gtmInitScript.innerHTML = `
    window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);}
    gtag('set', 'developer_id.${environment.tagManager.developerId}', true);
    gtag('consent', 'default', {
        'ad_storage': 'denied',
        'ad_user_data': 'denied',
        'ad_personalization': 'denied',
        'analytics_storage': 'denied'
    });
    gtag('js', new Date());
    gtag('config', '${environment.tagManager.trackingId}');
`;

head.insertBefore(gtmInitScript, head.children[4]);
head.insertBefore(gtmScript, head.children[4]);

// GTM (Noscript)
const gtmNoscript = document.createElement('noscript');
gtmNoscript.innerHTML = `
    <iframe src="https://www.googletagmanager.com/ns.html?id=${environment.tagManager.trackingId}" height="0" width="0" style="display:none;visibility:hidden"></iframe>
`;

body.insertBefore(gtmNoscript, body.firstChild);

const bootstrap = () =>
    bootstrapApplication(AppComponent, browserConfig)
        .then(() => {})
        .catch((err) => console.error(err));

if (environment.releaseStage === ReleaseStage.Development) {
    // HMR will provide you with a blank page if you add an event listener
    preBootstrap();
} else {
    document.addEventListener('DOMContentLoaded', () => {
        // see https://github.com/angular/angular-cli/issues/6683
        preBootstrap();
    });
}

function preBootstrap() {
    const logo: HTMLImageElement = document.getElementById(
        'logo',
    ) as HTMLImageElement;
    if (logo) {
        logo.src = '/assets/images/logos/logo-voetbalwedden.svg';
    }

    bootstrap().catch((err) => {});
}
