import { HttpErrorResponse } from '@angular/common/http';
import { ResponseResource } from '@app/core/services/http/response';
import { User, UserExperienceLevel } from '@app/core/services/user/user';
import { Action, createReducer, on } from '@ngrx/store';
import { updateUserSuccessAction } from '../../actions/users/user.actions';
import { InitialLoading, Loading } from '../shared/loading';
import {
    authenticateWithPasswordAction,
    authenticationFailedAction,
    authenticationSuccessAction,
    loadAuthenticatedUser,
    revokeAuthenticationAction,
    revokeAuthenticationFailureAction,
    revokeAuthenticationSuccessAction,
    storeAuthenticatedUserAction,
    storeAuthenticatedUserFailureAction,
    storeAuthenticatedUserSuccessAction,
    updateExperienceDataAction,
    verifyWithTokenAction,
} from './authentication.actions';

export interface AuthenticationState extends Loading {
    user: User;
    authenticated?: boolean;
    saveIsSuccessful?: boolean;
    saveHasFailed?: boolean;
    error?: HttpErrorResponse;
}

export const initialAuthenticationState: AuthenticationState = {
    ...InitialLoading,
    user: null,
    authenticated: false,
    saveIsSuccessful: false,
    saveHasFailed: false,
    error: undefined,
};

export const createAuthenticationReducer = createReducer(
    initialAuthenticationState,
    on(verifyWithTokenAction, (state: AuthenticationState) => ({
        ...state,
        loading: true,
    })),

    on(authenticateWithPasswordAction, (state: AuthenticationState) => ({
        ...state,
        loading: true,
    })),
    on(
        authenticationSuccessAction,
        (state: AuthenticationState, response: ResponseResource<User>) => ({
            ...state,
            user: response.data,
            authenticated: true,
            loading: false,
        }),
    ),
    on(authenticationFailedAction, (state: AuthenticationState) => ({
        ...state,
        user: null,
        authenticated: false,
        loading: false,
    })),

    on(revokeAuthenticationAction, (state: AuthenticationState) => state),
    on(revokeAuthenticationSuccessAction, (state: AuthenticationState) => ({
        ...state,
        user: null,
        authenticated: false,
    })),
    on(
        revokeAuthenticationFailureAction,
        (state: AuthenticationState) => state,
    ),

    on(storeAuthenticatedUserAction, (state: AuthenticationState) => ({
        ...state,
        loading: true,
        saveIsSuccessful: false,
        saveHasFailed: false,
    })),
    on(storeAuthenticatedUserSuccessAction, (state: AuthenticationState) => ({
        ...state,
        loading: false,
        saveIsSuccessful: true,
        saveHasFailed: false,
    })),
    on(
        storeAuthenticatedUserFailureAction,
        (
            state: AuthenticationState,
            payload: { error: HttpErrorResponse },
        ) => ({
            ...state,
            error: payload.error,
            loading: false,
        }),
    ),

    on(
        loadAuthenticatedUser,
        (state: AuthenticationState, response: ResponseResource<User>) => ({
            ...state,
            user: response.data,
            authenticated: true,
            loading: false,
        }),
    ),

    on(
        updateUserSuccessAction,
        (state: AuthenticationState, payload: ResponseResource<User>) => {
            if (payload.data.id !== state.user.id) {
                return state;
            }

            return {
                ...state,
                user: {
                    ...state.user,
                    ...payload.data,
                },
            };
        },
    ),

    on(
        updateExperienceDataAction,
        (
            state: AuthenticationState,
            props: {
                experienceLevel: UserExperienceLevel;
                experiencePoints: number;
            },
        ) => ({
            ...state,
            user: {
                ...state.user,
                experienceLevel: props.experienceLevel,
                experiencePoints: props.experiencePoints,
            },
        }),
    ),
);

export function authenticationReducer(
    state: AuthenticationState | undefined,
    action: Action,
): AuthenticationState {
    return createAuthenticationReducer(state, action);
}
