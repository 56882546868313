import {
    defaultShowItemAction,
    defaultShowItemFailureAction,
    defaultShowItemSuccessAction,
    defaultUpdateItemSuccessAction,
    FormState,
    initialFormState,
} from '@action/form-actions.actions';
import {
    loadNavigationBannerAction,
    loadNavigationBannerFailureAction,
    loadNavigationBannerSuccessAction,
    resetNavigationBannerAction,
    updateNavigationBannerAction,
} from '@action/navigation/banner.actions';
import { HttpErrorResponse } from '@angular/common/http';
import { Action, createReducer, on } from '@ngrx/store';
import { Banner } from '@service/banner/banner';
import { ResponseResource } from '@service/http/response';

export const navigationBannerFeatureKey = 'navigationBanner';

const navigationBannerReducer = createReducer(
    initialFormState,
    on(loadNavigationBannerAction, (state: FormState<Banner>) =>
        defaultShowItemAction<Banner>(state),
    ),
    on(
        loadNavigationBannerSuccessAction,
        (state: FormState<Banner>, payload: ResponseResource<Banner>) =>
            defaultShowItemSuccessAction<Banner>(state, payload),
    ),
    on(
        loadNavigationBannerFailureAction,
        (state: FormState<Banner>, payload: { error: HttpErrorResponse }) =>
            defaultShowItemFailureAction<Banner>(state, payload.error),
    ),

    on(
        updateNavigationBannerAction,
        (state: FormState<Banner>, payload: { data: Banner }) =>
            defaultUpdateItemSuccessAction<Banner>(state, payload),
    ),

    on(resetNavigationBannerAction, () => initialFormState),
);

export const reducer = (
    state: FormState<Banner> | undefined,
    action: Action,
): FormState<Banner> => navigationBannerReducer(state, action);
