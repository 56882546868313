import {
    defaultAddListItemAction,
    defaultLoadListAction,
    defaultLoadListFailureAction,
    defaultLoadListSuccessAction,
    defaultResetListStateAction,
    defaultUpdateListItemAction,
    defaultUpdateListItemFailureAction,
    initialListState,
    ListState,
} from '@action/list-actions.actions';
import {
    addNotificationAction,
    loadNotificationsAction,
    loadNotificationsFailureAction,
    loadNotificationsSuccessAction,
    resetNotificationsAction,
    updateNotificationAction,
    updateNotificationFailureAction,
    updateNotificationsAction,
    updateNotificationsFailureAction,
    updateNotificationsSuccessAction,
    updateNotificationSuccessAction,
} from '@action/notifications/notifications.actions';
import { HttpErrorResponse } from '@angular/common/http';
import { Action, createReducer, on } from '@ngrx/store';
import {
    ResponseResource,
    ResponseResourceCollection,
} from '@service/http/response';
import { Notification } from '@service/notification/notification';

export const reducer = (listState: ListState<Notification>, action: Action) =>
    createReducer(
        initialListState,
        on(loadNotificationsAction, (state: ListState<Notification>) =>
            defaultLoadListAction<Notification>(state),
        ),
        on(
            loadNotificationsSuccessAction,
            (
                state: ListState<Notification>,
                response: ResponseResourceCollection<Notification>,
            ) => defaultLoadListSuccessAction<Notification>(state, response),
        ),
        on(
            loadNotificationsFailureAction,
            (
                state: ListState<Notification>,
                response: { error: HttpErrorResponse },
            ) => defaultLoadListFailureAction<Notification>(state, response),
        ),

        on(updateNotificationAction, (state: ListState<Notification>) =>
            defaultUpdateListItemAction<Notification>(state),
        ),
        on(
            updateNotificationSuccessAction,
            (
                state: ListState<Notification>,
                response: ResponseResource<Notification>,
            ) => ({
                ...state,
                data: state.data.map((notification: Notification) =>
                    notification.id === response.data.id
                        ? response.data
                        : notification,
                ),
                meta: {
                    ...state.meta,
                    unreadCount:
                        state.meta.unreadCount > 0
                            ? state.meta.unreadCount - 1
                            : 0,
                },
                updateLoading: false,
            }),
        ),
        on(
            updateNotificationFailureAction,
            (
                state: ListState<Notification>,
                response: { error: HttpErrorResponse },
            ) =>
                defaultUpdateListItemFailureAction<Notification>(
                    state,
                    response.error,
                ),
        ),

        on(updateNotificationsAction, (state: ListState<Notification>) =>
            defaultUpdateListItemAction<Notification>(state),
        ),
        on(
            updateNotificationsSuccessAction,
            (state: ListState<Notification>) => ({
                ...state,
                data: state.data.map((notification: Notification) => ({
                    ...notification,
                    read: true,
                    readAt: new Date(),
                })),
                meta: {
                    ...state.meta,
                    unreadCount: 0,
                },
                updateLoading: false,
            }),
        ),
        on(
            updateNotificationsFailureAction,
            (
                state: ListState<Notification>,
                response: { error: HttpErrorResponse },
            ) =>
                defaultUpdateListItemFailureAction<Notification>(
                    state,
                    response.error,
                ),
        ),

        on(
            addNotificationAction,
            (
                state: ListState<Notification>,
                response: ResponseResource<Notification>,
            ) => defaultAddListItemAction<Notification>(state, response),
        ),

        on(resetNotificationsAction, () => defaultResetListStateAction()),
    )(listState, action);
