import { HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { uuid } from '@app/factories/client-uuid-factory';
import { CookiesEnum } from '@core/enums/cookies.enum';
import { environment } from '@environment/environment';
import { CookieService } from '@service/cookie.service';

export const defaultInterceptor: HttpInterceptorFn = (
    request: HttpRequest<unknown>,
    next: HttpHandlerFn,
) => {
    const cookieService = inject(CookieService);

    request = request.clone({
        setHeaders: {
            'application-token': environment.applicationToken,
            'X-Language': environment.langCode,
            'X-Project': environment.seoProject,
            'X-Client-UUID': cookieService.get('client_uuid') || uuid,
            'X-Api-Key': environment.applicationToken,
        },
    });

    if (cookieService.check(CookiesEnum.UTM_CAMPAIGN)) {
        request = request.clone({
            setHeaders: {
                'utm-campaign': cookieService.get(CookiesEnum.UTM_CAMPAIGN),
            },
        });
    }

    if (cookieService.check(CookiesEnum.COUNTRY_ISO)) {
        request = request.clone({
            setHeaders: {
                'X-Country-ISO': cookieService.get(CookiesEnum.COUNTRY_ISO),
            },
        });
    }

    return next(request);
};
