import {
    defaultAddListItemAction,
    defaultDeleteListItemAction,
    defaultLoadListAction,
    defaultLoadListFailureAction,
    defaultLoadListSuccessAction,
    initialListState,
    ListState,
} from '@action/list-actions.actions';
import {
    createUserTipCompetitionsSuccessAction,
    deleteUserTipCompetitionsSuccessAction,
    loadUserTipCompetitionsAction,
    loadUserTipCompetitionsFailureAction,
    loadUserTipCompetitionsSuccessAction,
} from '@action/users/user-tip-competitions.actions';
import { HttpErrorResponse } from '@angular/common/http';
import { Action, createReducer, on } from '@ngrx/store';
import {
    ResponseResource,
    ResponseResourceCollection,
} from '@service/http/response';
import { TipCompetitionUser } from '@service/tip-competition-user/tip-competition-user';

export const reducer = (state: ListState<TipCompetitionUser>, action: Action) =>
    createReducer(
        initialListState,

        on(
            loadUserTipCompetitionsAction,
            (listState: ListState<TipCompetitionUser>) =>
                defaultLoadListAction(listState),
        ),
        on(
            loadUserTipCompetitionsSuccessAction,
            (
                listState: ListState<TipCompetitionUser>,
                payload: ResponseResourceCollection<TipCompetitionUser>,
            ) => defaultLoadListSuccessAction(listState, payload),
        ),
        on(
            loadUserTipCompetitionsFailureAction,
            (
                listState: ListState<TipCompetitionUser>,
                payload: { error: HttpErrorResponse },
            ) => defaultLoadListFailureAction(listState, payload),
        ),

        on(
            createUserTipCompetitionsSuccessAction,
            (
                listState: ListState<TipCompetitionUser>,
                payload: ResponseResource<TipCompetitionUser>,
            ) => defaultAddListItemAction(listState, payload),
        ),

        on(
            deleteUserTipCompetitionsSuccessAction,
            (
                listState: ListState<TipCompetitionUser>,
                payload: { id: number },
            ) =>
                defaultDeleteListItemAction<TipCompetitionUser>(
                    listState,
                    payload,
                ),
        ),
    )(state, action);
