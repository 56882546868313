import {
    createUserVoteFailureAction,
    createUserVoteSuccessAction,
    deleteUserVoteFailureAction,
    deleteUserVoteSuccessAction,
    updateUserVoteFailureAction,
    updateUserVoteSuccessAction,
    UserVotesActionType,
} from '@action/users/user-votes.actionts';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppState } from '@app/app.state';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ResponseResource } from '@service/http/response';
import { Vote, VoteProps, VoteRequest } from '@service/vote/vote';
import { VoteService } from '@service/vote/vote.service';
import { of } from 'rxjs';
import { catchError, finalize, map, mergeMap } from 'rxjs/operators';

@Injectable()
export class UserVotesEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly voteService: VoteService,
        private readonly store: Store<AppState>,
    ) {}

    /**
     * Create create user votes effect.
     *
     * @returns Observable
     *
     * @author Sander van Ooijen <svanooijen@bettercollective.com>
     * @version 1.0.0
     */
    public createUserVote$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserVotesActionType.CREATE_USER_VOTE),
            mergeMap((data: VoteProps<VoteRequest>) =>
                this.voteService
                    .store({
                        ...data.payload,
                    })
                    .pipe(
                        map((response: ResponseResource<Vote>) =>
                            createUserVoteSuccessAction(response),
                        ),
                        catchError((error: HttpErrorResponse) =>
                            of(
                                createUserVoteFailureAction({
                                    request: data.payload,
                                    error,
                                }),
                            ),
                        ),
                    ),
            ),
        ),
    );

    /**
     * Create update user votes effect.
     *
     * @returns Observable
     *
     * @author Sander van Ooijen <svanooijen@bettercollective.com>
     * @version 1.0.0
     */
    public updateUserVote$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserVotesActionType.UPDATE_USER_VOTE),
            mergeMap((data: VoteProps<VoteRequest>) =>
                this.voteService.update(data.payload.id, data.payload).pipe(
                    map((response: ResponseResource<Vote>) =>
                        updateUserVoteSuccessAction(response),
                    ),
                    catchError((error: HttpErrorResponse) =>
                        of(
                            updateUserVoteFailureAction({
                                request: data.payload,
                                error,
                            }),
                        ),
                    ),
                ),
            ),
        ),
    );

    /**
     * Create delete user votes effect.
     *
     * @returns Observable
     *
     * @author Sander van Ooijen <svanooijen@bettercollective.com>
     * @version 1.0.0
     */
    public deleteUserVote$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserVotesActionType.DELETE_USER_VOTE),
            mergeMap((data: { id: number }) => {
                let hasError = false;

                return this.voteService.delete(data.id).pipe(
                    catchError((error: HttpErrorResponse) => {
                        hasError = true;

                        return of(deleteUserVoteFailureAction({ id: data.id }));
                    }),
                    finalize(() => {
                        if (!hasError) {
                            this.store.dispatch(
                                deleteUserVoteSuccessAction({ id: data.id }),
                            );
                        }
                    }),
                    map(() => deleteUserVoteSuccessAction({ id: data.id })),
                );
            }),
        ),
    );
}
