import { HttpEvent, HttpHandlerFn, HttpInterceptorFn, HttpRequest, HttpResponse } from '@angular/common/http';
import camelcaseKeys from 'camelcase-keys';
import { map } from 'rxjs';
import snakecaseKeys from 'snakecase-keys';
import { RequestType } from '../response';

/**
 * JSON Interceptor
 * Changes the JSON object inside a response body to a JSON object with only camelCased keys.
 * Changes the JSON object inside a request body to a JSON object with only snake_cased keys.
 * This enforces us to be consistent with request and response parameters
 *
 * @author Sander van Ooijen <svanooijen@bettercollective.com>
 * @version 1.0.0
 */
export const jsonInterceptor: HttpInterceptorFn = (
    request: HttpRequest<unknown>,
    next: HttpHandlerFn,
) => {
    if (
        request.method === RequestType.post ||
        request.method === RequestType.put ||
        request.method === RequestType.patch
    ) {
        try {
            request = request.clone({
                body: snakecaseKeys(JSON.parse(request.body as string), {
                    deep: true,
                }),
            });
        } catch (e) {
            return next(request);
        }
    }

    return next(request).pipe(
        map((event: HttpEvent<any>) => {
            if (
                !request.url.includes('i18next') &&
                event instanceof HttpResponse &&
                event.body
            ) {
                return event.clone({
                    body: camelcaseKeys(event.body, {
                        deep: true,
                        exclude: ['achievements', /-[0-9]*/g],
                    }),
                });
            } else {
                return event;
            }
        }),
    );
};
