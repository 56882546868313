import {
    defaultAddListItemAction,
    defaultDeleteListItemAction,
    defaultLoadListAction,
    defaultLoadListFailureAction,
    defaultLoadListSuccessAction,
    initialListState,
    ListState,
} from '@action/list-actions.actions';
import {
    createUserForumTopicsSuccessAction,
    deleteUserForumTopicsSuccessAction,
    loadUserForumTopicsAction,
    loadUserForumTopicsFailureAction,
    loadUserForumTopicsSuccessAction,
} from '@action/users/user-forum-topics.actions';
import { HttpErrorResponse } from '@angular/common/http';
import { Action, createReducer, on } from '@ngrx/store';
import {
    ResponseResource,
    ResponseResourceCollection,
} from '@service/http/response';
import { UserTopic } from '@service/user-topic/user-topic';

export const reducer = (state: ListState<UserTopic>, action: Action) =>
    createReducer(
        initialListState,

        on(loadUserForumTopicsAction, (listState: ListState<UserTopic>) =>
            defaultLoadListAction(listState),
        ),
        on(
            loadUserForumTopicsSuccessAction,
            (
                listState: ListState<UserTopic>,
                payload: ResponseResourceCollection<UserTopic>,
            ) => defaultLoadListSuccessAction(listState, payload),
        ),
        on(
            loadUserForumTopicsFailureAction,
            (
                listState: ListState<UserTopic>,
                payload: { error: HttpErrorResponse },
            ) => defaultLoadListFailureAction(listState, payload),
        ),

        on(
            createUserForumTopicsSuccessAction,
            (
                listState: ListState<UserTopic>,
                payload: ResponseResource<UserTopic>,
            ) => defaultAddListItemAction(listState, payload),
        ),

        on(
            deleteUserForumTopicsSuccessAction,
            (listState: ListState<UserTopic>, payload: { id: number }) =>
                defaultDeleteListItemAction<UserTopic>(listState, payload),
        ),
    )(state, action);
