import {
    createUserForumTopicsFailureAction,
    createUserForumTopicsSuccessAction,
    deleteUserForumTopicsFailureAction,
    deleteUserForumTopicsSuccessAction,
    loadUserForumTopicsFailureAction,
    loadUserForumTopicsSuccessAction,
    UserForumTopicsActionType,
} from '@action/users/user-forum-topics.actions';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
    ResponseResource,
    ResponseResourceCollection,
} from '@service/http/response';
import {
    UserTopic,
    UserTopicIndexParameters,
    UserTopicProps,
    UserTopicStoreParameters,
} from '@service/user-topic/user-topic';
import { UserTopicService } from '@service/user-topic/user-topic.service';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

@Injectable()
export class UserForumTopicsEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly userTopicService: UserTopicService,
    ) {}

    /**
     * Create load user forum topics effect.
     *
     * @returns Observable
     *
     * @author Sander van Ooijen <svanooijen@bettercollective.com>
     * @version 1.0.0
     */
    public loadUserForumTopics$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserForumTopicsActionType.LOAD_USER_FORUM_TOPICS),
            mergeMap((data: UserTopicProps<UserTopicIndexParameters>) =>
                this.userTopicService
                    .index({
                        ...data.payload,
                    })
                    .pipe(
                        map((response: ResponseResourceCollection<UserTopic>) =>
                            loadUserForumTopicsSuccessAction(response),
                        ),
                        catchError((error: HttpErrorResponse) =>
                            of(
                                loadUserForumTopicsFailureAction({
                                    error,
                                }),
                            ),
                        ),
                    ),
            ),
        ),
    );

    /**
     * Create create user forum topics effect.
     *
     * @returns Observable
     *
     * @author Sander van Ooijen <svanooijen@bettercollective.com>
     * @version 1.0.0
     */
    public createUserForumTopic$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserForumTopicsActionType.CREATE_USER_FORUM_TOPICS),
            mergeMap((data: UserTopicProps<UserTopicStoreParameters>) =>
                this.userTopicService
                    .store({
                        ...data.payload,
                    })
                    .pipe(
                        map((response: ResponseResource<UserTopic>) =>
                            createUserForumTopicsSuccessAction(response),
                        ),
                        catchError((error: HttpErrorResponse) =>
                            of(
                                createUserForumTopicsFailureAction({
                                    error,
                                }),
                            ),
                        ),
                    ),
            ),
        ),
    );

    /**
     * Create delete user forum topics effect.
     *
     * @returns Observable
     *
     * @author Sander van Ooijen <svanooijen@bettercollective.com>
     * @version 1.0.0
     */
    public deleteUserForumTopic$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserForumTopicsActionType.DELETE_USER_FORUM_TOPICS),
            mergeMap((data: { id: number }) =>
                this.userTopicService.delete(data.id).pipe(
                    map(() =>
                        deleteUserForumTopicsSuccessAction({ id: data.id }),
                    ),
                    catchError((error: HttpErrorResponse) =>
                        of(deleteUserForumTopicsFailureAction({ id: data.id })),
                    ),
                ),
            ),
        ),
    );
}
