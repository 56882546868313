import {
    FormState,
    initialFormState,
    defaultShowItemAction,
    defaultShowItemFailureAction,
} from '@action/form-actions.actions';
import { Action, createReducer, on } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { ResponseResource } from '@service/http/response';
import { StaticBlock } from '@service/static-block/static-block';
import { ResponseResourceCollection } from '@core/services/http/response';
import {
    loadStaticBlocksAction,
    loadStaticBlocksFailureAction,
    loadStaticBlocksSuccessAction,
    resetStaticBlockAction,
    showStaticBlockAction,
    showStaticBlockFailureAction,
    showStaticBlockSuccessAction,
} from '@action/static-block/static-block.actions';

export interface StaticBlockState {
    [key: string]: StaticBlock;
}

export const initialStaticBlockState: FormState<StaticBlockState> = {
    ...initialFormState,
    data: {},
};

const reducer = createReducer(
    initialStaticBlockState,
    on(showStaticBlockAction, (state: FormState<StaticBlockState>) =>
        defaultShowItemAction(state),
    ),
    on(
        showStaticBlockSuccessAction,
        (
            state: FormState<StaticBlockState>,
            payload: ResponseResource<StaticBlock>,
        ) => ({
            ...state,
            loading: false,
            data: {
                ...state.data,
                [payload.data.codeName]: payload.data,
            },
        }),
    ),
    on(
        showStaticBlockFailureAction,
        (
            state: FormState<StaticBlockState>,
            payload: { error: HttpErrorResponse },
        ) => defaultShowItemFailureAction(state, payload),
    ),

    on(loadStaticBlocksAction, (state: FormState<StaticBlockState>) =>
        defaultShowItemAction(state),
    ),
    on(
        loadStaticBlocksSuccessAction,
        (
            state: FormState<StaticBlockState>,
            payload: ResponseResourceCollection<StaticBlock>,
        ) => {
            const staticBlocks: StaticBlockState = {};
            payload.data.forEach((staticBlock: StaticBlock) => {
                staticBlocks[staticBlock.codeName] = staticBlock;
            });

            return {
                ...state,
                loading: false,
                data: {
                    ...state.data,
                    ...staticBlocks,
                },
            };
        },
    ),
    on(
        loadStaticBlocksFailureAction,
        (
            state: FormState<StaticBlockState>,
            payload: { error: HttpErrorResponse },
        ) => defaultShowItemFailureAction(state, payload),
    ),

    on(resetStaticBlockAction, () => initialStaticBlockState),
);

export const staticBlockReducer = (
    state: FormState<StaticBlockState> | undefined,
    action: Action,
): FormState<StaticBlockState> => reducer(state, action);
