import { HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';

import { AuthService } from '@service/auth/auth.service';

export const authInterceptor: HttpInterceptorFn = (
    request: HttpRequest<unknown>,
    next: HttpHandlerFn,
) => {
    const authService = inject(AuthService);

    if (authService.getToken()) {
        request = request.clone({
            setHeaders: {
                Authorization: 'Bearer ' + authService.getToken(),
            },
        });
    }

    return next(request);
};
