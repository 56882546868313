import {
    loadNavigationBannerFailureAction,
    loadNavigationBannerSuccessAction,
    NavigationBannerActionsEnum,
} from '@action/navigation/banner.actions';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Banner, BannerTypeEnum } from '@service/banner/banner';
import { BannerService } from '@service/banner/banner.service';
import { ResponseResource } from '@service/http/response';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

@Injectable()
export class NavigationBannerEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly bannerService: BannerService,
    ) {}

    /**
     * Load navigation banner effect
     *
     * @author Sander van Ooijen <sander@bsbip.com>
     * @version 1.0.0
     */
    public loadNavigationBanner$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NavigationBannerActionsEnum.LOAD_NAVIGATION_BANNER),
            mergeMap((data: { payload: { type: BannerTypeEnum } }) =>
                this.bannerService.showActive(data.payload.type).pipe(
                    map((response: ResponseResource<Banner>) =>
                        loadNavigationBannerSuccessAction(response),
                    ),
                    catchError((error: HttpErrorResponse) =>
                        of(loadNavigationBannerFailureAction(error)),
                    ),
                ),
            ),
        ),
    );
}
