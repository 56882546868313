import { Injectable } from '@angular/core';
import { Response } from '@interface/response';
import { HttpService } from '@service/http/http.service';
import {
    ResponseResource,
    ResponseResourceCollection,
} from '@service/http/response';
import { Observable } from 'rxjs';
import {
    IndexNotificationRequestParameters,
    Notification,
    UpdateNotificationRequestParameters,
} from './notification';

@Injectable({
    providedIn: 'root',
})
export class NotificationService {
    public baseUrl = '/v2/notifications';

    constructor(private readonly httpService: HttpService) {}

    /**
     * @link ApiServiceInterface#index
     *
     * @author Koen Lukkien <klukkien@bettercollective.com>
     * @version 1.0.0
     */
    public index(props?: {
        payload: IndexNotificationRequestParameters;
    }): Observable<ResponseResourceCollection<Notification>> {
        let params = {};

        if (props) {
            params = {
                ...props.payload,
            };
        }

        return this.httpService.get(this.baseUrl, {
            params,
        });
    }

    /**
     * @link ApiServiceInterface#update
     *
     * @author Koen Lukkien <klukkien@bettercollective.com>
     * @author Jouri Roosjen <jroosjen@bettercollective.com>
     * @version 1.0.1
     */
    public update(
        id: string,
        data: UpdateNotificationRequestParameters,
    ): Observable<ResponseResource<Notification>> {
        return this.httpService.put(
            `${this.baseUrl}/${id}`,
            data as unknown as object,
        );
    }

    /**
     * Bulk updates notifications (mark all notifications as read)
     *
     * @returns response message
     *
     * @author Sander van Ooijen <svanooijen@bettercollective.com>
     * @version 1.0.0
     */
    public bulkUpdate(): Observable<Response> {
        return this.httpService.put(this.baseUrl, { isRead: true });
    }
}
