import { Injectable } from '@angular/core';
import { HttpService } from '@service/http/http.service';
import { ResponseResource } from '@service/http/response';
import { Observable } from 'rxjs';
import { GeoData } from './geo-data';

@Injectable({
    providedIn: 'root',
})
export class GeoDataService {
    public baseUrl = '/v2/geo-data';

    constructor(private readonly httpService: HttpService) {}

    /**
     * Retrieve geo data
     *
     * @returns GeoData
     *
     * @author Sander van Ooijen <sander@bsbip.com>
     * @version 1.0.0
     */
    public show(): Observable<ResponseResource<GeoData>> {
        return this.httpService.get(this.baseUrl);
    }
}
