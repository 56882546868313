import {
    setHamburgerNavigationItemsAction,
    setMainNavigationItemsAction,
    updateNavigationItemAction,
} from '@action/navigation/navigation-items.actions';
import { NavigationItem } from '@core/components/navigation/navigation-data';
import { Action, createReducer, on } from '@ngrx/store';

export const navigationItemsFeatureKey = 'navigationItems';

export interface NavigationItemsState {
    main: NavigationItem[];
    hamburger: NavigationItem[];
}

const initialNavigationItemsState: NavigationItemsState = {
    main: [],
    hamburger: [],
};

const metadataReducer = createReducer(
    initialNavigationItemsState,
    on(
        setMainNavigationItemsAction,
        (state: NavigationItemsState, props: { data: NavigationItem[] }) => {
            return {
                ...state,
                main: props.data,
            };
        },
    ),
    on(
        setHamburgerNavigationItemsAction,
        (state: NavigationItemsState, props: { data: NavigationItem[] }) => {
            return {
                ...state,
                hamburger: props.data,
            };
        },
    ),
    on(
        updateNavigationItemAction,
        (state: NavigationItemsState, props: { data: NavigationItem }) => {
            return {
                ...state,
                main: state.main.map((navigationItem: NavigationItem) => {
                    if (navigationItem.label === props.data.label) {
                        return props.data;
                    }

                    return navigationItem;
                }),
            };
        },
    ),
);

export function reducer(
    state: NavigationItemsState | undefined,
    action: Action,
): NavigationItemsState {
    return metadataReducer(state, action);
}
