import {
    ApplicationConfig,
    importProvidersFrom,
    mergeApplicationConfig,
} from '@angular/core';
import { environment } from '@environment/environment';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { appConfig } from './app.config';

const config: ApplicationConfig = {
    providers: [
        provideStoreDevtools({
            maxAge: 25,
            logOnly: environment.production,
        }),
        importProvidersFrom(TimepickerModule.forRoot()),
    ],
};

export const browserConfig = mergeApplicationConfig(appConfig, config);
