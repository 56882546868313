import {
    FormState,
    defaultShowItemAction,
    defaultShowItemFailureAction,
    defaultShowItemSuccessAction,
    initialFormState,
} from '@action/form-actions.actions';
import {
    loadGeoDataAction,
    loadGeoDataFailureAction,
    loadGeoDataSuccessAction,
} from '@action/geo-data/geo-data.actions';
import { HttpErrorResponse } from '@angular/common/http';
import { Action, createReducer, on } from '@ngrx/store';
import { GeoData } from '@service/geo-data/geo-data';
import { ResponseResource } from '@service/http/response';

export const geoDataFeatureKey = 'geoData';

const geoDataReducer = createReducer(
    initialFormState,
    on(loadGeoDataAction, (state: FormState<GeoData>) =>
        defaultShowItemAction<GeoData>(state),
    ),
    on(
        loadGeoDataSuccessAction,
        (state: FormState<GeoData>, payload: ResponseResource<GeoData>) =>
            defaultShowItemSuccessAction<GeoData>(state, payload),
    ),
    on(
        loadGeoDataFailureAction,
        (state: FormState<GeoData>, payload: { error: HttpErrorResponse }) =>
            defaultShowItemFailureAction<GeoData>(state, payload.error),
    ),
);

export const reducer = (
    state: FormState<GeoData> | undefined,
    action: Action,
): FormState<GeoData> => geoDataReducer(state, action);
