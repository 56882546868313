import { DatePipe } from '@angular/common';
import {
    provideHttpClient,
    withFetch,
    withInterceptors,
} from '@angular/common/http';
import {
    APP_ID,
    APP_INITIALIZER,
    ApplicationConfig,
    LOCALE_ID,
    importProvidersFrom,
} from '@angular/core';
import { provideClientHydration } from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import {
    provideRouter,
    withEnabledBlockingInitialNavigation,
    withInMemoryScrolling,
    withRouterConfig,
} from '@angular/router';
import { environment } from '@environment/environment';
import { EffectsModule } from '@ngrx/effects';
import { provideRouterStore } from '@ngrx/router-store';
import { Store, StoreModule, provideStore } from '@ngrx/store';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { AuthService } from '@service/auth/auth.service';
import { authenticationFactory } from '@service/auth/authentication.factory';
import { CookieService } from '@service/cookie.service';
import { DomService } from '@service/dom.service';
import { geoDataFactory } from '@service/geo-data/geo-data.factory';
import { authInterceptor } from '@service/http/interceptors/auth-token.interceptor';
import { defaultInterceptor } from '@service/http/interceptors/default.interceptor';
import { jsonInterceptor } from '@service/http/interceptors/json.interceptor';
import { microcopyInterceptor } from '@service/http/interceptors/microcopy.interceptor';
import { orderDirectionInterceptor } from '@service/http/interceptors/order-direction.interceptor';
import { Angulartics2Module } from 'angulartics2';
import { BsModalRef, BsModalService, ModalModule } from 'ngx-bootstrap/modal';
import { provideQuillConfig } from 'ngx-quill';
import { routes } from './app.routes';
import { clientUUIDFactory } from './factories/client-uuid-factory';
import { effects, reducers } from './reducers';

export const appConfig: ApplicationConfig = {
    providers: [
        {
            provide: APP_ID,
            useValue: 'app-root',
        },
        {
            provide: APP_INITIALIZER,
            useFactory: clientUUIDFactory,
            deps: [CookieService],
            multi: true,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: authenticationFactory,
            deps: [Store, AuthService, DomService],
            multi: true,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: geoDataFactory,
            deps: [Store],
            multi: true,
        },
        {
            provide: LOCALE_ID,
            useValue: environment.langCode,
        },
        importProvidersFrom(
            LoadingBarRouterModule,
            EffectsModule.forRoot(effects),
            ModalModule.forRoot(),
            StoreModule.forRoot(reducers, {
                runtimeChecks: {
                    strictStateImmutability: true,
                    strictActionImmutability: true,
                },
            }),
            Angulartics2Module.forRoot({
                developerMode: false,
                ga: {
                    anonymizeIp: true,
                },
            }),
        ),
        DatePipe,
        BsModalService,
        BsModalRef,
        provideClientHydration(),
        provideRouterStore(),
        provideAnimationsAsync(),
        provideQuillConfig({}),
        provideStore(reducers, {
            runtimeChecks: {
                strictStateImmutability: true,
                strictActionImmutability: true,
            },
        }),
        provideHttpClient(
            withFetch(),
            withInterceptors([
                defaultInterceptor,
                authInterceptor,
                jsonInterceptor,
                microcopyInterceptor,
                orderDirectionInterceptor,
            ]),
        ),
        provideRouter(
            routes,
            withEnabledBlockingInitialNavigation(),
            withInMemoryScrolling({
                anchorScrolling: 'enabled',
                scrollPositionRestoration: 'enabled',
            }),
            withRouterConfig({
                paramsInheritanceStrategy: 'always',
                urlUpdateStrategy: 'eager',
            }),
        ),
    ],
};
