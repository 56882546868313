import { inject } from '@angular/core';
import { ResolveFn, Router } from '@angular/router';

import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthService } from '@service/auth/auth.service';

export const forgotPasswordResolver: ResolveFn<Object> = (route, state) => {
    const router = inject(Router);

    return inject(AuthService)
        .forgotPasswordCode(route.params['code'])
        .pipe(
            catchError(() => {
                router.navigate(['/404'], {
                    skipLocationChange: true,
                    queryParams: {
                        url: state.url,
                    },
                });

                return of();
            }),
        );
};
