import { lowerCase } from "lower-case";
// Support camel case ("camelCase" -> "camel Case" and "CAMELCase" -> "CAMEL Case").
var DEFAULT_SPLIT_REGEXP = [/([a-z0-9])([A-Z])/g, /([A-Z])([A-Z][a-z])/g];
// Remove all non-word characters.
var DEFAULT_STRIP_REGEXP = /[^A-Z0-9]+/gi;
/**
 * Normalize the string into something other libraries can manipulate easier.
 */
export function noCase(input, options) {
  if (options === void 0) {
    options = {};
  }
  var _a = options.splitRegexp,
    splitRegexp = _a === void 0 ? DEFAULT_SPLIT_REGEXP : _a,
    _b = options.stripRegexp,
    stripRegexp = _b === void 0 ? DEFAULT_STRIP_REGEXP : _b,
    _c = options.transform,
    transform = _c === void 0 ? lowerCase : _c,
    _d = options.delimiter,
    delimiter = _d === void 0 ? " " : _d;
  var result = replace(replace(input, splitRegexp, "$1\0$2"), stripRegexp, "\0");
  var start = 0;
  var end = result.length;
  // Trim the delimiter from around the output string.
  while (result.charAt(start) === "\0") start++;
  while (result.charAt(end - 1) === "\0") end--;
  // Transform each token independently.
  return result.slice(start, end).split("\0").map(transform).join(delimiter);
}
/**
 * Replace `re` in the input string with the replacement value.
 */
function replace(input, re, value) {
  if (re instanceof RegExp) return input.replace(re, value);
  return re.reduce(function (input, re) {
    return input.replace(re, value);
  }, input);
}
