import { PlatformModule } from '@angular/cdk/platform';
import {
    CommonModule,
    DOCUMENT,
    ViewportScroller,
    registerLocaleData,
} from '@angular/common';
import localeNlExtra from '@angular/common/locales/extra/nl';
import localeNl from '@angular/common/locales/nl';
import {
    ChangeDetectionStrategy,
    Component,
    Inject,
    OnDestroy,
    afterNextRender,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { JsonLdComponent } from '@core/components/json-ld/json-ld.component';
import { environment } from '@environment/environment';
import { PushPipe } from '@ngrx/component';
import { Actions, ofType } from '@ngrx/effects';
import {
    RouterNavigatedPayload,
    RouterRequestPayload,
    routerNavigatedAction,
    routerRequestAction,
} from '@ngrx/router-store';
import { AlertService } from '@service/alert/alert.service';
import { DomService } from '@service/dom.service';
import { FooterService } from '@service/footer/footer.service';
import { LinkService } from '@service/link/link.service';
import { RichSnippetService } from '@service/RichSnippet/rich-snippet.service';
import { SearchService } from '@service/search/search.service';
import { WebSocketService } from '@service/web-socket/web-socket.service';
import { HeaderService } from '@shared/components/header/header.service';
import { AdVantagePlatformService } from '@shared/services/ad-vantage-platform.service';
import {
    Angulartics2GoogleAnalytics,
    Angulartics2GoogleTagManager,
} from 'angulartics2';
import { defineLocale, nlLocale } from 'ngx-bootstrap/chronos';
import { Subscription, filter, skip } from 'rxjs';
import { getCLS, getFCP, getFID, getLCP, getTTFB } from 'web-vitals';

declare global {
    interface Window {
        dataLayer: any[];
    }
}

interface AnalyticsParameters {
    name: string;
    delta: number;
    id: string;
}

@Component({
    selector: 'app-root',
    standalone: true,
    templateUrl: './app.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        RouterModule,
        PlatformModule,
        CommonModule,
        JsonLdComponent,
        FormsModule,
        PushPipe,
    ],
})
export class AppComponent implements OnDestroy {
    private routerEventsSubscription$!: Subscription;
    private routerNavigationEndSubscription$!: Subscription;
    private routerNavigatedSubscription$!: Subscription;

    constructor(
        @Inject(DOCUMENT) private readonly domDocument: Document,
        private readonly domService: DomService,
        private readonly footerService: FooterService,
        private readonly headerService: HeaderService,
        private readonly linkService: LinkService,
        private readonly viewportScroller: ViewportScroller,
        private readonly actions$: Actions,
        private readonly webSocketService: WebSocketService,
        private readonly adVantagePlatform: AdVantagePlatformService,
        private readonly richSnippetService: RichSnippetService,
        private readonly alertService: AlertService,
        private readonly searchService: SearchService,
        private readonly angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics,
        private readonly angulartics2GoogleTagManager: Angulartics2GoogleTagManager,
    ) {
        this.domDocument.documentElement.lang = environment.langCode;

        this.angulartics2GoogleAnalytics.startTracking();
        this.angulartics2GoogleTagManager.startTracking();

        this.linkService.startRouteListener();

        registerLocaleData(localeNl, 'nl', localeNlExtra);

        defineLocale('nl', nlLocale);
        defineLocale('nl-nl', nlLocale);
        defineLocale('nl-NL', nlLocale);

        afterNextRender(() => {
            this.generateWebVitals();

            // Check on adblocker
            this.domService.checkAdblocker();

            // Connect to web socket service
            this.webSocketService.connect();

            // Fetch ads
            this.adVantagePlatform.displayAdFromAdVantagePlatform();

            // Own implementation to scroll to top
            // Do not scroll to top if there are query params added
            this.routerNavigatedSubscription$ = this.actions$
                .pipe(
                    ofType(routerNavigatedAction),
                    filter(
                        (event: { payload: RouterNavigatedPayload }) =>
                            !event.payload.routerState.url.includes('?'),
                    ),
                )
                .subscribe(() =>
                    this.viewportScroller.scrollToPosition([0, 0]),
                );

            this.routerNavigationEndSubscription$ = this.actions$
                .pipe(
                    ofType(routerNavigatedAction),
                    filter(
                        (event: { payload: RouterNavigatedPayload }) =>
                            !event.payload.routerState.url.includes('?'),
                    ),
                    skip(1),
                )
                .subscribe(() => this.headerService.clear());

            this.routerEventsSubscription$ = this.actions$
                .pipe(ofType(routerRequestAction))
                .subscribe((event: { payload: RouterRequestPayload }) => {
                    this.footerService.setMarginTop(true);

                    this.richSnippetService.clear();

                    this.alertService.clear();

                    if (!event.payload.routerState.url.includes('zoeken')) {
                        this.searchService.searchOpen$.next(false);
                    }
                });
        });
    }

    /**
     * Send web vitals to GA
     *
     * @author Sander van Ooijen <sander@bsbip.com>
     * @version 1.0.0
     */
    private sendToGoogleAnalytics(data: AnalyticsParameters): void {
        this.domService.nativeWindow.dataLayer.push({
            event: data.name,
            data: {
                category: 'Web Vitals',
                label: data.id,
                value: Math.round(
                    data.name === 'CLS' ? data.delta * 1000 : data.delta,
                ),
                noninteraction: true,
                transport: 'beacon',
            },
        });
    }

    /**
     * Generate web vitals
     *
     * @author Sander van Ooijen <sander@bsbip.com>
     * @version 1.0.0
     */
    private generateWebVitals(): void {
        getCLS((data) => this.sendToGoogleAnalytics(data));
        getFID((data) => this.sendToGoogleAnalytics(data));
        getLCP((data) => this.sendToGoogleAnalytics(data));
        getFCP((data) => this.sendToGoogleAnalytics(data));
        getTTFB((data) => this.sendToGoogleAnalytics(data));
    }

    /**
     * Handle on destroy lifecycle.
     *
     * @author Roy Freij <roy@bsbip.com>
     * @author Ramon Bakker <ramon@bsbip.com>
     * @version 1.1.1
     */
    public ngOnDestroy(): void {
        if (this.routerEventsSubscription$) {
            this.routerEventsSubscription$.unsubscribe();
        }

        if (this.routerNavigationEndSubscription$) {
            this.routerNavigationEndSubscription$.unsubscribe();
        }

        if (this.routerNavigatedSubscription$) {
            this.routerNavigatedSubscription$.unsubscribe();
        }
    }
}
