import { __assign } from "tslib";
import { dotCase } from "dot-case";
export function snakeCase(input, options) {
  if (options === void 0) {
    options = {};
  }
  return dotCase(input, __assign({
    delimiter: "_"
  }, options));
}
