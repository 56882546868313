import { CookieService } from '@service/cookie.service';
import { v4 as uuidv4 } from 'uuid';

export const uuid = uuidv4();

export function clientUUIDFactory(cookieService: CookieService): Function {
    return async () => {
        return new Promise((resolve: Function) => {
            if (!cookieService.check('client_uuid')) {
                const expires = new Date();

                expires.setFullYear(new Date().getFullYear() + 2);

                cookieService.set('client_uuid', uuid, expires);
            }

            resolve();
        });
    };
}
