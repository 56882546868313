import { Injectable } from '@angular/core';
import { VotableTypeEnum } from '@core/enums/votable-type.enum';
import { FilterService } from '@service/filter/filter.service';
import { HttpService } from '@service/http/http.service';
import { ResponseResourceCollection } from '@service/http/response';
import { User } from '@service/user/user';
import { ApiService } from '@shared/services/api.service';
import { Observable } from 'rxjs';
import { Vote, VoteRequest } from './vote';

@Injectable({
    providedIn: 'root',
})
export class VoteService extends ApiService<Vote, null, VoteRequest> {
    public baseUrl = '/v2/votes';

    constructor(
        protected readonly httpService: HttpService,
        protected readonly filterService: FilterService,
    ) {
        super(httpService, filterService);
    }

    /**
     * Retrieve vote users
     *
     * @param votableType
     * @param votableId
     *
     * @returns users
     *
     * @author Sander van Ooijen <svanooijen@bettercollective.com>
     * @author Jouri Roosjen <jroosjen@bettercollective.com>
     * @version 1.0.1
     */
    public users(
        votableType: VotableTypeEnum,
        votableId: number,
    ): Observable<ResponseResourceCollection<User>> {
        return this.httpService.get('/v2/retrieve-vote-users', {
            params: {
                votableType,
                votableId,
            },
        });
    }
}
