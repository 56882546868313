import {
    createUserTipCompetitionsFailureAction,
    createUserTipCompetitionsSuccessAction,
    deleteUserTipCompetitionsFailureAction,
    deleteUserTipCompetitionsSuccessAction,
    loadUserTipCompetitionsFailureAction,
    loadUserTipCompetitionsSuccessAction,
    UserTipCompetitionsActionType,
} from '@action/users/user-tip-competitions.actions';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppState } from '@app/app.state';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { selectAuthenticatedUser } from '@reducer/authenticated-user/authentication.selectors';
import { AnalyticsAction } from '@service/analytics/analytics-action.enum';
import { AnalyticsCategory } from '@service/analytics/analytics-category.enum';
import {
    ResponseResource,
    ResponseResourceCollection,
} from '@service/http/response';
import {
    TipCompetitionUser,
    TipCompetitionUserProps,
    TipCompetitionUserRequestParameters,
    TipCompetitionUserStoreRequestParameters,
} from '@service/tip-competition-user/tip-competition-user';
import { TipCompetitionUserService } from '@service/tip-competition-user/tip-competition-user.service';
import { User } from '@service/user/user';
import { of } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AnalyticsService } from '../../core/services/analytics/analytics.service';

@Injectable()
export class UserTipCompetitionsEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly tipCompetitionUserService: TipCompetitionUserService,
        private readonly analyticsService: AnalyticsService,
        private readonly store: Store<AppState>,
    ) {}

    /**
     * Create load user tip competitions effect.
     *
     * @returns Observable
     *
     * @author Sander van Ooijen <svanooijen@bettercollective.com>
     * @version 1.0.0
     */
    public loadUserTipCompetitions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserTipCompetitionsActionType.LOAD_USER_TIP_COMPETITIONS),
            mergeMap(
                (
                    data: TipCompetitionUserProps<TipCompetitionUserRequestParameters>,
                ) =>
                    this.tipCompetitionUserService
                        .index(data.payload.tipCompetitionSlug, {
                            ...data.payload,
                        })
                        .pipe(
                            map(
                                (
                                    response: ResponseResourceCollection<TipCompetitionUser>,
                                ) =>
                                    loadUserTipCompetitionsSuccessAction(
                                        response,
                                    ),
                            ),
                            catchError((error: HttpErrorResponse) =>
                                of(
                                    loadUserTipCompetitionsFailureAction({
                                        error,
                                    }),
                                ),
                            ),
                        ),
            ),
        ),
    );

    /**
     * Create create user tip competitions effect.
     *
     * @returns Observable
     *
     * @author Sander van Ooijen <svanooijen@bettercollective.com>
     * @version 1.0.0
     */
    public createUserTipCompetition$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserTipCompetitionsActionType.CREATE_USER_TIP_COMPETITIONS),
            withLatestFrom(this.store.select(selectAuthenticatedUser)),
            mergeMap(
                ([data, user]: [
                    TipCompetitionUserProps<TipCompetitionUserStoreRequestParameters>,
                    User,
                ]) =>
                    this.tipCompetitionUserService
                        .store({
                            ...data.payload,
                        })
                        .pipe(
                            map(
                                (
                                    response: ResponseResource<TipCompetitionUser>,
                                ) => {
                                    this.analyticsService.interaction(
                                        AnalyticsAction.JOIN_SUBLEAGUE,
                                        AnalyticsCategory.INTERN_CLICK,
                                        response.data.tipCompetition.translation
                                            .name,
                                    );

                                    this.analyticsService.interaction(
                                        AnalyticsAction.UGC_MAIN_TIPSTER_COMPETITION_SUBLEAGUES,
                                        AnalyticsCategory.UGC_MAIN,
                                        response.data.tipCompetition.translation
                                            .name,
                                    );

                                    this.analyticsService.interaction(
                                        response.data.tipCompetition.translation
                                            .name,
                                        AnalyticsCategory.UGC_SUB_SUB_TIPSTER_COMPETITIONS_SUBLEAGUES,
                                        user.username,
                                    );

                                    this.analyticsService.interaction(
                                        response.data.tipCompetition.translation
                                            .name,
                                        AnalyticsCategory.TIPCOMPETITION_SUBLEAGUES_SUB_JOIN_SUBLEAGUE,
                                        user.username,
                                    );

                                    this.analyticsService.interaction(
                                        user.username,
                                        AnalyticsCategory.TIPCOMPETITION_SUBLEAGUES_SUB_JOIN_SUBLEAGUE_BY_USER,
                                        response.data.tipCompetition.translation
                                            .name,
                                    );

                                    this.analyticsService.interaction(
                                        AnalyticsAction.TIPCOMPETITION_SUBLEAGUE_MAIN_JOIN_SUBLEAGUE_BY_USER,
                                        AnalyticsCategory.TIPCOMPETITION_SUBLEAGUES_MAIN,
                                        user.username,
                                    );

                                    this.analyticsService.interaction(
                                        AnalyticsAction.TIPCOMPETITION_SUBLEAGUE_MAIN_JOIN_SUBLEAGUE,
                                        AnalyticsCategory.TIPCOMPETITION_SUBLEAGUES_MAIN,
                                        response.data.tipCompetition.translation
                                            .name,
                                    );

                                    return createUserTipCompetitionsSuccessAction(
                                        response,
                                    );
                                },
                            ),
                            catchError((error: HttpErrorResponse) =>
                                of(
                                    createUserTipCompetitionsFailureAction({
                                        error,
                                    }),
                                ),
                            ),
                        ),
            ),
        ),
    );

    /**
     * Create delete user tip competitions effect.
     *
     * @returns Observable
     *
     * @author Sander van Ooijen <svanooijen@bettercollective.com>
     * @version 1.0.0
     */
    public deleteUserTipCompetition$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserTipCompetitionsActionType.DELETE_USER_TIP_COMPETITIONS),
            mergeMap((data: { id: number }) =>
                this.tipCompetitionUserService.delete(data.id).pipe(
                    map(() =>
                        deleteUserTipCompetitionsSuccessAction({ id: data.id }),
                    ),
                    catchError((error: HttpErrorResponse) =>
                        of(
                            deleteUserTipCompetitionsFailureAction({
                                id: data.id,
                            }),
                        ),
                    ),
                ),
            ),
        ),
    );
}
