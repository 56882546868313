import {
    loadNotificationsFailureAction,
    loadNotificationsSuccessAction,
    NotificationsActionsEnum,
    updateNotificationFailureAction,
    updateNotificationsFailureAction,
    updateNotificationsSuccessAction,
    updateNotificationSuccessAction,
} from '@action/notifications/notifications.actions';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppState } from '@app/app.state';
import {
    ResponseResource,
    ResponseResourceCollection,
} from '@app/core/services/http/response';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
    selectNotificationsLimit,
    selectNotificationsPage,
} from '@selector/notifications/notifications.selector';
import {
    IndexNotificationRequestParameters,
    Notification,
    NotificationProps,
    UpdateNotificationRequestParameters,
} from '@service/notification/notification';
import { NotificationService } from '@service/notification/notification.service';
import { of } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';

@Injectable()
export class NotificationsEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly notificationService: NotificationService,
        private readonly store: Store<AppState>,
    ) {}

    /**
     * Create load notifications effect.
     *
     * @returns Observable of effect.
     *
     * @author Sander van Ooijen <svanooijen@bettercollective.com>
     * @version 1.0.0
     */
    public loadNotificationsEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NotificationsActionsEnum.LOAD_NOTIFICATIONS),
            withLatestFrom(
                this.store.select(selectNotificationsPage),
                this.store.select(selectNotificationsLimit),
            ),
            mergeMap(
                ([props, page, limit]: [
                    NotificationProps<IndexNotificationRequestParameters>,
                    number,
                    number,
                ]) =>
                    this.notificationService
                        .index({ payload: { page, limit, ...props.payload } })
                        .pipe(
                            map(
                                (
                                    response: ResponseResourceCollection<Notification>,
                                ) => loadNotificationsSuccessAction(response),
                            ),
                            catchError((error: HttpErrorResponse) =>
                                of(
                                    loadNotificationsFailureAction({
                                        error,
                                    }),
                                ),
                            ),
                        ),
            ),
        ),
    );

    /**
     * Update notification effect.
     *
     * @returns Observable of effect.
     *
     * @author Sander van Ooijen <svanooijen@bettercollective.com>
     * @version 1.0.0
     */
    public updateNotificationEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NotificationsActionsEnum.UPDATE_NOTIFICATION),
            mergeMap(
                (
                    props: NotificationProps<UpdateNotificationRequestParameters>,
                ) =>
                    this.notificationService
                        .update(props.payload.id, props.payload)
                        .pipe(
                            map((response: ResponseResource<Notification>) =>
                                updateNotificationSuccessAction(response),
                            ),
                            catchError((error: HttpErrorResponse) =>
                                of(
                                    updateNotificationFailureAction({
                                        error,
                                    }),
                                ),
                            ),
                        ),
            ),
        ),
    );

    /**
     * Update notifications effect.
     *
     * @returns Observable of effect.
     *
     * @author Sander van Ooijen <svanooijen@bettercollective.com>
     * @version 1.0.0
     */
    public updateNotificationsEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NotificationsActionsEnum.UPDATE_NOTIFICATIONS),
            mergeMap(() =>
                this.notificationService.bulkUpdate().pipe(
                    map(() => updateNotificationsSuccessAction()),
                    catchError((error: HttpErrorResponse) =>
                        of(
                            updateNotificationsFailureAction({
                                error,
                            }),
                        ),
                    ),
                ),
            ),
        ),
    );
}
