import { loadNotificationsAction } from '@action/notifications/notifications.actions';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppState } from '@app/app.state';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AuthService } from '@service/auth/auth.service';
import { DomService } from '@service/dom.service';
import { ResponseResource } from '@service/http/response';
import { StoreUserRequestParameters, User } from '@service/user/user';
import { UserService } from '@service/user/user.service';
import { WebSocketService } from '@service/web-socket/web-socket.service';
import { catchError, map, mergeMap, of, tap } from 'rxjs';
import {
    AuthenticationActionsEnum,
    authenticationFailedAction,
    authenticationSuccessAction,
    revokeAuthenticationFailureAction,
    revokeAuthenticationSuccessAction,
    storeAuthenticatedUserFailureAction,
    storeAuthenticatedUserSuccessAction,
} from './authentication.actions';

@Injectable()
export class AuthenticationEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly authenticationService: AuthService,
        private readonly userService: UserService,
        private readonly store: Store<AppState>,
        private readonly webSocketService: WebSocketService,
        private readonly domService: DomService,
    ) {}

    /**
     * Create user authenticate with token effect
     *
     * @returns effect
     *
     * @author Roy Freij <roy@bsbip.com>
     * @version 1.0.0
     */
    public authenticateWithToken$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthenticationActionsEnum.AUTHENTICATE_WITH_TOKEN),
            mergeMap(() =>
                this.authenticationService.checkLogin().pipe(
                    map((response: ResponseResource<User>) =>
                        authenticationSuccessAction(response),
                    ),
                    catchError((response: HttpErrorResponse) =>
                        of(
                            authenticationFailedAction({
                                error: response,
                            }),
                        ),
                    ),
                ),
            ),
        ),
    );

    /**
     * Create user authenticate with password effect
     *
     * @returns effect
     *
     * @author Roy Freij <roy@bsbip.com>
     * @version 1.0.0
     */
    public authenticateWithPassword$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthenticationActionsEnum.AUTHENTICATE_WITH_PASSWORD),
            mergeMap(
                ({ email, password }: { email: string; password: string }) => {
                    this.authenticationService.removeToken();

                    return this.authenticationService
                        .login({ email, password })
                        .pipe(
                            map((response: ResponseResource<User>) =>
                                authenticationSuccessAction(response),
                            ),
                            catchError((response: HttpErrorResponse) =>
                                of(
                                    authenticationFailedAction({
                                        error: response,
                                    }),
                                ),
                            ),
                        );
                },
            ),
        ),
    );

    /**
     * Store authenticated user effects.
     *
     * @returns Observable of effect.
     *
     * @author Jouri Roosjen <jroosjen@bettercollective.com>
     * @version 1.0.0
     */
    public storeAuthenticatedUserEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthenticationActionsEnum.STORE_AUTHENTICATED_USER),
            mergeMap((data: StoreUserRequestParameters) => {
                return this.userService.store(data).pipe(
                    map((response: ResponseResource<User>) =>
                        storeAuthenticatedUserSuccessAction(response),
                    ),
                    catchError((response: HttpErrorResponse) =>
                        of(
                            storeAuthenticatedUserFailureAction({
                                error: response,
                            }),
                        ),
                    ),
                );
            }),
        ),
    );

    /**
     * Create load authenticated user effect.
     *
     * @returns effect
     *
     * @author Niek van der Velde <niek@bsbio.com>
     * @version 1.0.0
     */
    public loadAuthenticatedUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthenticationActionsEnum.LOAD_AUTHENTICATED_USER),
            map((response: ResponseResource<User>) =>
                authenticationSuccessAction(response),
            ),
        ),
    );

    /**
     * Create authentication success effect
     *
     * @returns effect
     *
     * @author Roy Freij <roy@bsbip.com>
     * @author Niek van der Velde <niek@bsbio.com>
     * @author Jouri Roosjen <jroosjen@bettercollective.com>
     * @version 1.1.2
     */
    public authenticationSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AuthenticationActionsEnum.AUTHENTICATION_SUCCESS),
                tap((response: ResponseResource<User>) => {
                    this.authenticationService.setLoggedInCookie('1');
                    this.authenticationService.setToken(response.data.token);

                    this.store.dispatch(
                        loadNotificationsAction({
                            payload: { page: 1 },
                        }),
                    );

                    if (this.domService.isBrowser) {
                        this.webSocketService.authenticate();
                    }
                }),
            ),
        { dispatch: false },
    );

    /**
     * Create authentication failure effect
     *
     * @returns effect
     *
     * @author Roy Freij <roy@bsbip.com>
     * @author Niek van der Velde <niek@bsbio.com>
     * @version 1.1.0
     */
    public authenticationFailure$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(AuthenticationActionsEnum.AUTHENTICATION_FAILURE),
                tap(() => {
                    this.authenticationService.setLoggedInCookie('0');
                    this.authenticationService.removeToken();
                }),
            );
        },
        { dispatch: false },
    );

    /**
     * Create revoke authentication effect
     *
     * @returns effect
     *
     * @author Roy Freij <roy@bsbip.com>
     * @author Sander van Ooijen <sander@bsbip.com>
     * @version 1.0.1
     */
    public revokeAuthentication$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuthenticationActionsEnum.REVOKE_AUTHENTICATION),
            mergeMap(() =>
                this.authenticationService.logout().pipe(
                    map(() => revokeAuthenticationSuccessAction()),
                    catchError((response: HttpErrorResponse) =>
                        of(
                            revokeAuthenticationFailureAction({
                                error: response,
                            }),
                        ),
                    ),
                ),
            ),
        );
    });

    /**
     * Create revoke authentication success effect
     *
     * @returns effect
     *
     * @author Roy Freij <roy@bsbip.com>
     * @author Niek van der Velde <niek@bsbio.com>
     * @author Sander van Ooijen <sander@bsbip.com>
     * @author Jouri Roosjen <jroosjen@bettercollective.com>
     * @version 1.1.3
     */
    public revokeAuthenticationSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(AuthenticationActionsEnum.REVOKE_AUTHENTICATION_SUCCESS),
                tap((response: boolean) => {
                    this.authenticationService.removeToken();
                    this.authenticationService.setLoggedInCookie('0');
                }),
            );
        },
        { dispatch: false },
    );
}
