import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { DomService } from '@service/dom.service';
import { Subscription, timer } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class AdVantagePlatformService implements OnDestroy {
    public delaySubscription$: Subscription;

    constructor(
        @Inject(DOCUMENT) private document,
        private domService: DomService,
    ) {}

    /**
     * Display Ads from ad-vantage platform
     * See component: ad-vantage-platform
     *
     * @author Rody Korthout <rkorthout@bettercollective.com>
     * @version 1.0.0
     */
    public displayAdFromAdVantagePlatform(): void {
        if (this.domService.isBrowser) {
            this.delaySubscription$ = timer(500).subscribe(() =>
                this.fetchAdFromAdVantagePlatform(),
            );
        }
    }

    /**
     * Fetch ads from ad-vantage platform
     *
     * @author Rody Korthout <rkorthout@bettercollective.com>
     * @version 1.0.0
     */
    private fetchAdFromAdVantagePlatform() {
        if (!this.document) {
            return;
        }

        const loadRequested: string = `bc:ap:ads:load:requested`;

        const event = (eventName) =>
            new CustomEvent(eventName, {
                bubbles: true,
                composed: true,
            });

        this.document.dispatchEvent(event(loadRequested));
    }

    /**
     * On Destroy
     *
     * @author Rody Korthout <rkorthout@bettercollective.com>
     * @version 1.0.0
     */
    public ngOnDestroy(): void {
        if (this.delaySubscription$) {
            this.delaySubscription$.unsubscribe();
        }
    }
}
